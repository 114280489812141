import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer id="footer" className="footer light-background">
                <div className="container footer-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-4 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><a href="#">About Us</a></li>
                                <li><a href="#">Products</a></li>
                                <li><a href="#">Grievance Redress Policy</a></li>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">License</a></li>
                                <li><a href="#">ISNP</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><a href="#">Car Insurance</a></li>
                                <li><a href="#">Health Insurance</a></li>
                                <li><a href="#">Term Insurance</a></li>
                                <li><a href="#">Fire Insurance</a></li>
                                <li><a href="#">Liability Insurance</a></li>
                                <li><a href="#">Marine Insurance</a></li>
                                <li><a href="#">Two-Wheeler Insurance</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="footer-contact text-center text-md-start">
                                <h4>Contact Us</h4>
                                <p>C/O Baijnath Jaiswal</p>
                                <p>Mahadeva Buzurg, Anand nagar</p>
                                <p>Maharajganj UP 273155 IN</p>
                                <p className="mt-4"><strong>Phone:</strong> <span>+91 9519166608</span></p>
                                <p><strong>Email:</strong> <span>AMIT3188@GMAIL.COM</span></p>
                            </div>
                            <div className="social-links d-flex mt-4">
                                <a href="#"><i className="fa-brands fa-x-twitter"></i></a>
                                <a href="#"><i className="fa-brands fa-facebook"></i></a>
                                <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container copyright text-center mt-4">
                    <p>AIBROM INSURANCE BROKING PRIVATE LIMITED | Reg. Office - C/O Baijnath Jaiswal Mahadeva Buzurg, Anand nagar, Maharajganj UP 273155 IN | IRDAI Direct Insurance Broker (Life & General) | License No.- 922 | Valid From - 07/12/2023 to Valid Till - 06/12/2026 Code No. - IRDAI/DB1040/2023 | CIN No - U67200UP2022PTC170642 | GST No - 09AAXCA6331L1Z0 | T&C Apply* Copyright © AIBROM INSURANCE BROKING PRIVATE LIMITED. All Rights Reserved</p>
                </div>
            </footer>

        </>
    );
};

export default Footer;
